import React from 'react';
import { TabsList, TabsTrigger } from '../../ui/tabs';
import { Code2, Lightbulb, Search, FileText } from 'lucide-react';

function BuddyTabs() {
  return (
    <TabsList className="grid grid-cols-4 w-full">
      <TabsTrigger value="explainer" className="flex items-center gap-2">
        <Code2 className="w-4 h-4" />
        Explainer
      </TabsTrigger>
      <TabsTrigger value="analyzer" className="flex items-center gap-2">
        <Search className="w-4 h-4" />
        Analyzer
      </TabsTrigger>
      <TabsTrigger value="hints" className="flex items-center gap-2">
        <Lightbulb className="w-4 h-4" />
        Hints
      </TabsTrigger>
      <TabsTrigger value="feedback" className="flex items-center gap-2">
        <FileText className="w-4 h-4" />
        Feedback
      </TabsTrigger>
    </TabsList>
  );
}

export default BuddyTabs;
