import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { Alert, AlertDescription } from '../../ui/alert';
import { Button } from '../../ui/button';
import { Tabs, TabsContent } from '../../ui/tabs';
import { ScrollArea } from '../../ui/scroll-area';

import BuddyTabs from './Tabs';
import { useBuddy } from '../../../context/useBuddy';
import MarkdownView from '../../MarkDownView';
import TimeComplexityChart from './ChartCofig';
import HighlightedCardContent from './Code';

const PlayArea = () => {
  const {
    loading,
    code,
    setCode,
    analysis,
    selectedTab,
    setSelectedTab,
    analyzeCode,
    generateFeedback,
    generateHint,
    generateExplanation,
    explanation,
  } = useBuddy();

  return (
    <ScrollArea className="w-3/5 pr-3">
      <div className="grid grid-cols-1 gap-6 pt-3">
        {/* Features Tabs */}

        <Tabs defaultValue="problem" value={selectedTab} onValueChange={setSelectedTab}>
          <BuddyTabs />

          {/* Previous tab contents remain the same */}
          <TabsContent value="explainer">
            <Card className="bg-muted">
              <CardHeader>
                <CardTitle>Problem Explanation</CardTitle>
              </CardHeader>
              <CardContent>
                <MarkdownView>{explanation}</MarkdownView>
                <Button disabled={loading} onClick={generateExplanation}>
                  Regenerate Explanation
                </Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="hints">
            <Card>
              <CardHeader>
                <CardTitle>Helpful Hints</CardTitle>
              </CardHeader>
              <CardContent>
                {code ? (
                  <div className="space-y-4">
                    <Alert>
                      <AlertDescription>{generateHint()}</AlertDescription>
                    </Alert>
                    <Button disabled>Get Another Hint</Button>
                  </div>
                ) : (
                  <Alert>
                    <AlertDescription>
                      Write your solution above to get helpful hints
                    </AlertDescription>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          {/* Code Input Section - Moved under tabs */}
          <HighlightedCardContent code={code} setCode={setCode} />

          <TabsContent value="analyzer">
            <Card>
              <CardHeader>
                <CardTitle>Code Analysis</CardTitle>
              </CardHeader>
              <CardContent>
                {analysis && (
                  <div className="flex gap-1 mb-3">
                    <div>
                      <h2 className="font-bold text-lg mb-2">Time Complexity:</h2>
                      <TimeComplexityChart complexity={analysis.tc} />
                    </div>
                    <div>
                      <h2 className="font-bold text-lg mb-2">Space Complexity:</h2>
                      <TimeComplexityChart complexity={analysis.sc} />
                    </div>
                  </div>
                )}
                {code ? (
                  <Button disabled={loading} onClick={analyzeCode}>
                    Analyze Code
                  </Button>
                ) : (
                  <Alert>
                    <AlertDescription>
                      Paste your solution above to get a detailed analysis
                    </AlertDescription>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="feedback">
            <Card>
              <CardHeader>
                <CardTitle>Feedback Report</CardTitle>
              </CardHeader>
              <CardContent>
                {code ? (
                  <div className="space-y-4">
                    {Object.entries(generateFeedback()).map(([key, value]) => (
                      <div key={key} className="space-y-2">
                        <h3 className="font-medium capitalize">{key}</h3>
                        {Array.isArray(value) ? (
                          <ul className="list-disc pl-5 space-y-1">
                            {value.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <p>{value}</p>
                        )}
                      </div>
                    ))}
                    <Button disabled>Generate New Feedback</Button>
                  </div>
                ) : (
                  <Alert>
                    <AlertDescription>
                      Write your solution above to get detailed feedback
                    </AlertDescription>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </ScrollArea>
  );
};

export default PlayArea;
